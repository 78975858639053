import React, { useEffect, useState } from 'react';
import { DocumentIcon, EditIcon, RightArrow } from 'assets';
import Table from 'components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import ReactMarkdown from 'react-markdown';
import { getJsonValue } from 'services/utils';

const PdfBenchMarks = ({ fundkey, fundData }) => {
  const [columnList, setColumnList] = useState([]);
  const [fundDetail, setFundDetail] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(5);
  const { fundBenchmark } = useSelector((state) => state.fundData);
  console.log('pdf fundBenchmark = ', fundBenchmark);
  const columnsToHaveFooter = {
    IRR: 1,
    DPI: 2,
    TVPI: 3,
  };

  const shouldDisplayFooterValue = (key) => {
    const normalizedKey = key.toLowerCase(); // Normalize by converting to lowercase

    if (normalizedKey.includes('multi') || normalizedKey.includes('quartile')) {
      const matchingColumnKey = Object.keys(columnsToHaveFooter).find(
        (columnKey) => normalizedKey.includes(columnKey.toLowerCase()) // Normalize column keys as well
      );

      if (matchingColumnKey) {
        return columnsToHaveFooter[matchingColumnKey];
      }
    }

    return ''; // Return an empty string if no matching footer value is found
  };

  const renderMarkdown = (text) => (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <span {...props} />,
      }}
    >
      {text}
    </ReactMarkdown>
  );

  const renderSplit = (text) => {
    // Split the text on the '|' delimiter
    const partsText = text.split('|');
    const footerValue = shouldDisplayFooterValue(text);

    // Return a div with styled text components and a dotted line separator
    return (
      <div className="flex">
        <div className="split-text-container">
          {/* Render the first part of the text */}
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => <span {...props} />,
            }}
          >
            {partsText[0]}
          </ReactMarkdown>

          {/* Conditionally render the dotted line and second part if it exists */}
          {partsText.length > 1 && (
            <>
              <div className="dotted-line"></div>
              <ReactMarkdown
                components={{
                  p: ({ node, ...props }) => <span {...props} />,
                }}
              >
                {partsText[1]}
              </ReactMarkdown>
            </>
          )}
        </div>
        {footerValue && <span className="footer-value-pdf">{footerValue}</span>}
      </div>
    );
  };

  const toDoColumns = {
    fund_name_vintage: 'Fund Name (Vintage)',
    benchmark_fund_size: 'Target Size',
    invested_amount: 'Invested Amount',
    realized_amount: 'Realized Amount',
    benchmark_total_value: 'Total Value',
    benchmark_net_moic: 'Fund Net MOIC',
    fund_net_irr_quartile: renderSplit('Fund Net IRR(%)|Fund IRR Quartile'),
    fund_net_dpi_quartile: renderSplit('Fund Net DPI(%)|Fund DPI Quartile'),
    fund_net_tvpi_quartile: renderSplit('Fund Net TVPI(%)|Fund TVPI Quartile'),
    multi_net_irr_quartile: renderSplit('Multi Net IRR|Multi IRR Quartile'),
    multi_net_dpi_quartile: renderSplit('Multi Net DPI|Multi DPI Quartile'),
    multi_net_tvpi_quartile: renderSplit('Multi Net TVPI|Multi TVPI Quartile'),
  };

  const mapRow = (benchmark) => {
    const newRow = {};
    Object.keys(toDoColumns).forEach((column) => {
      newRow[column] = mapColumn(benchmark, column);
    });
    newRow['source_doc'] = benchmark.detail?.source_doc;
    newRow['source_page'] = benchmark.detail?.source_page;
    newRow['modified'] =
      (benchmark.detail?.modified_by_user_id !== null &&
        benchmark.detail?.modified_by_user_id !== undefined) ||
      (benchmark.quartile?.modified_by_user_id !== null &&
        benchmark.quartile?.modified_by_user_id !== undefined);
    newRow['benchmark_id'] = benchmark.benchmark_id;
    return newRow;
  };

  const mapColumn = (benchmark, column) => {
    const ind_detail = benchmark.detail;
    const ind_quartile = benchmark.quartile;
    const mapColumnToValue = {
      fund_name_vintage: ind_detail?.benchmark_fund_name
        ? `${ind_detail.benchmark_fund_name} (${ind_detail?.vintage_year || 'N/A'})`
        : 'N/A',
      benchmark_fund_size: `${ind_detail?.target_size}`,
      invested_amount: `${ind_detail?.invested_amount}`,
      realized_amount: `${ind_detail?.realized_amount}`,
      benchmark_total_value: `${ind_detail?.total_value}`,
      benchmark_net_moic: `${ind_detail?.fund_net_moic}`,
      fund_net_irr_quartile: `${ind_detail?.fund_net_irr || 'N/A'} | ${ind_quartile?.fund_irr_quartile || 'N/A'}`,
      fund_net_dpi_quartile: `${ind_detail?.fund_net_dpi || 'N/A'} | ${ind_quartile?.fund_dpi_quartile || 'N/A'}`,
      fund_net_tvpi_quartile: `${ind_detail?.fund_net_tvpi || 'N/A'} | ${ind_quartile?.fund_tvpi_quartile || 'N/A'}`,
      multi_net_irr_quartile: `${ind_quartile?.multi_net_irr || 'N/A'} | ${ind_quartile?.multi_irr_quartile || 'N/A'}`,
      multi_net_dpi_quartile: `${ind_quartile?.multi_net_dpi || 'N/A'} | ${ind_quartile?.multi_dpi_quartile || 'N/A'}`,
      multi_net_tvpi_quartile: `${ind_quartile?.multi_net_tvpi || 'N/A'} | ${ind_quartile?.multi_tvpi_quartile || 'N/A'}`,
    };
    return mapColumnToValue[column] !== null &&
      mapColumnToValue[column] !== 'null' &&
      mapColumnToValue[column] !== undefined &&
      mapColumnToValue[column] !== 'undefined'
      ? mapColumnToValue[column]
      : 'N/A';
  };

  useEffect(() => {
    if (fundBenchmark) {
      const dynamicColumns = Object.keys(toDoColumns).map((key) => ({
        Header: toDoColumns[key],
        accessor: key,
      }));
      console.log('pdf fundBenchmarks ### = ', fundBenchmark);
      const benchmarkArray = fundBenchmark.benchmarks;
      const newRows =
        benchmarkArray?.length > 0
          ? benchmarkArray.map((benchmark) => {
              const dynamicRow = mapRow(benchmark);
              const splitColumnList = [
                'fund_net_irr_quartile',
                'fund_net_dpi_quartile',
                'fund_net_tvpi_quartile',
                'multi_net_irr_quartile',
                'multi_net_dpi_quartile',
                'multi_net_tvpi_quartile',
              ];

              dynamicColumns.forEach((col) => {
                if (splitColumnList.includes(col.accessor)) {
                  dynamicRow[col.accessor] =
                    dynamicRow[col.accessor] !== 'null' &&
                    dynamicRow[col.accessor] !== undefined
                      ? renderSplit(dynamicRow[col.accessor])
                      : 'N/A';
                } else {
                  dynamicRow[col.accessor] =
                    dynamicRow[col.accessor] !== 'null' &&
                    dynamicRow[col.accessor] !== undefined
                      ? renderMarkdown(dynamicRow[col.accessor])
                      : 'N/A';
                }
              });

              return dynamicRow;
            })
          : [];

      setColumnList(dynamicColumns);
      setFundDetail(newRows);
      setVisibleData(newRows);
    }
  }, [fundkey, fundData, itemsToShow]);

  const hasAnyValue = (obj) =>
    Object.values(obj).some(
      (value) =>
        value !== '' &&
        value !== undefined &&
        value !== null &&
        value !== 'null'
    );

  const renderFooters = (asof_dates) => {
    return (
      asof_dates && (
        <ul className="footer-list">
          {Object.keys(asof_dates).map((measure) => {
            const asofDate = asof_dates[measure] || 'N/A';
            if (asofDate) {
              return (
                <li key={measure}>
                  {columnsToHaveFooter[measure]} ({measure}) As of: {asofDate}
                </li>
              );
            }
            return null; // Return null if there's no asofDate for this measure
          })}
        </ul>
      )
    );
  };

  return (
    <div className="blackbook-new">
      <Table
        allowAdd
        allowExport
        onAdd={alert}
        data={visibleData}
        isLoading={false}
        columns={columnList}
        customSorting={false}
        customPagination={false}
        addButtonName="Add User"
        // scrollStyle="scrollable-fund scroll fix-height"
        ContainerClass="table-container-fund blackbook-new scrollable"
      />
      {renderFooters(fundBenchmark?.asof_dates)}
    </div>
  );
};

export default PdfBenchMarks;
