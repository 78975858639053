import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchFundDetails,
  fetchFundDocuments,
  fetchFundDocumentsType,
  fetchFundPdfUrl,
  fetchFundSectionReview,
  fetchPdfFund,
  fetchSectionList,
  updateFundStatus,
  fetchDDDetails,
  updateDDList,
  fetchUsersList,
  fetchCioUsersList,
  fetchSourceDocList,
  fetchScoringMatrix,
  updateScoringMatrix,
  fetchSendEmail,
  fetchFundBenchmark,
  updateFundBenchmark,
  addFundBenchmark,
  deleteFundBenchmark,
  fetchSendEmailTemplate,
} from './FundActions';

const initialState = {
  homeData: {},
};

export const getUsersList = createAsyncThunk(
  'FETCH_USER_LIST',
  async (requestObject) => {
    const { data, status } = await fetchUsersList(requestObject);
    return { data, status };
  }
);

export const getCioUsersList = createAsyncThunk(
  'FETCH_CIO_USER_LIST',
  async (requestObject) => {
    const { data, status } = await fetchCioUsersList(requestObject);
    return { data, status };
  }
);

export const sendEmailAdd = createAsyncThunk(
  'SEND_EMAIL',
  async (requestObject) => {
    const { data, status } = await fetchSendEmail(requestObject);
    return { data, status };
  }
);

export const sendEmailTemplate = createAsyncThunk(
  'SEND_EMAIL_TEMPLATE',
  async (requestObject) => {
    const { data, status } = await fetchSendEmailTemplate(requestObject);
    return { data, status };
  }
);

export const getDDChecklist = createAsyncThunk(
  'GET_DD_DETAILS',
  async (requestObject) => {
    const { data, status } = await fetchDDDetails(requestObject);
    return { data, status };
  }
);

export const getFundBenchmark = createAsyncThunk(
  'GET_DD_DETAILS',
  async (requestObject) => {
    const { data, status } = await fetchFundBenchmark(requestObject);
    return { data, status };
  }
);

export const updateFundBenchmarkWithJson = createAsyncThunk(
  'SET_FUND_BENCHMARK',
  async (requestObject) => {
    const { data, status } = await updateFundBenchmark(requestObject);
    return { data, status };
  }
);

export const addBenchmarkRow = createAsyncThunk(
  'ADD_BENCHMARK_ROW',
  async (requestObject) => {
    const { data, status } = await addFundBenchmark(requestObject);
    return { data, status };
  }
);

export const deleteBenchmarkRow = createAsyncThunk(
  'DELETE_BENCHMARK_ROW',
  async (requestObject) => {
    const { data, status } = await deleteFundBenchmark(requestObject);
    return { data, status };
  }
);

export const getScoringMatrix = createAsyncThunk(
  'GET_SCORING_MATRIX',
  async (requestObject) => {
    const { data, status } = await fetchScoringMatrix(requestObject);
    return { data, status };
  }
);

export const updateDDChecklist = createAsyncThunk(
  'UPDATE_FUND_STATUS',
  async (requestObject) => {
    const { data, status } = await updateDDList(requestObject);
    return { data, status };
  }
);

export const updateScoringMatrixTab = createAsyncThunk(
  'UPDATE_SCORE_MATRIX',
  async (requestObject) => {
    const { data, status } = await updateScoringMatrix(requestObject);
    return { data, status };
  }
);

export const getfundDetails = createAsyncThunk(
  'GET_FUND_DETAILS',
  async (requestObject) => {
    const { data, status } = await fetchFundDetails(requestObject);
    return { data, status };
  }
);

export const getPdfFund = createAsyncThunk(
  'GET_PDF_FUND',
  async (requestObject) => {
    const { data, status } = await fetchPdfFund(requestObject);
    return { data, status };
  }
);

export const updateFundDocument = createAsyncThunk(
  'GET_FUND_DOC_TYPE',
  async (requestObject) => {
    const { data, status } = await fetchFundDocumentsType(requestObject);
    return { data, status };
  }
);

export const setSectionList = createAsyncThunk(
  'GET_SECTION_LIST',
  async (requestObject) => {
    const { data, status } = await fetchSectionList(requestObject);
    return { data, status };
  }
);

export const getFundSectionReviews = createAsyncThunk(
  'GET_FUND_SECTION_REVIEW',
  async (requestObject) => {
    const { data, status } = await fetchFundSectionReview(requestObject);
    return { data, status };
  }
);

export const getSourceDocList = createAsyncThunk(
  'GET_SOURCE_DOC_LIST',
  async (requestObject) => {
    const { data, status } = await fetchSourceDocList(requestObject);
    return { data, status };
  }
);

export const getFundPdfUrl = createAsyncThunk(
  'GET_FUND_PDF',
  async (requestObject) => {
    const { data, status } = await fetchFundPdfUrl(requestObject);
    return { data, status };
  }
);

export const getFundDocument = createAsyncThunk(
  'GET_FUND_DOC',
  async (requestObject) => {
    const { data, status } = await fetchFundDocuments(requestObject);
    return { data, status };
  }
);

export const updateFundStatusByID = createAsyncThunk(
  'UPDATE_FUND_STATUS',
  async (requestObject) => {
    const data = await updateFundStatus(requestObject);
    return { data: data.data };
  }
);

export const fundSlice = createSlice({
  name: 'funddetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfundDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getfundDetails.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.homeData = payload?.data;
      })
      .addCase(getfundDetails.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default fundSlice.reducer;
