import React, { useEffect, useRef, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import ReactDrawer from 'react-modern-drawer';
import { IoCloseOutline } from 'react-icons/io5';
import { oneOf, bool, string, node, func } from 'prop-types';
import { PrimaryButton, Modal, CustomButton } from 'ui-controls';
import { DocumentIcon, caution } from 'assets';
import 'react-modern-drawer/dist/index.css';
import './styles.css';
import DocumentTypeDropdown from 'modules/home/components/DocumentTypeDropdown';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getfundDetails } from 'modules/fund/Slice/FundSlice';
import { setFundData } from 'modules/fund/Slice/FundDataSlice';

const Drawer = ({
  isCollapsed = false,
  setIsCollapsed = () => {},
  isCollapse = false,
  id = 'modern-drawer',
  originalKeyName = '',
  children,
  fundkey = '',
  setLoading = () => {},
  isDrawerOpen = false,
  previewUnCat = false,
  setDrawerOpen = () => {},
  setHasChat = () => {},
  title = 'Drawer',
  AiChatScroll = false,
  onClose = () => {},
  subTitle = '',
  direction = 'right',
  drawerStyle,
  isConfirmModalOpen,
  isConfirmPreviewModal = false,
  hasChat = false,
  ...rest
}) => {
  let menu = useRef();
  // const dispatch = useDispatch();
  // const handleSaveAIResponse = () => {
  //   if (hasChat && isCollapse) {
  //     setLoading(true);
  //     dispatch(getfundDetails(fundkey)).then((res) => {
  //       if (res?.payload?.status === 200) {
  //         const fundDataRes = res?.payload?.data?.data;
  //         dispatch(setFundData(fundDataRes));
  //         setLoading(false);
  //         setHasChat(false);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    let handler = (e) => {
      const isBothModalsClosed =
        !isConfirmModalOpen && !previewUnCat && !isConfirmPreviewModal;

      if (
        menu.current &&
        !menu.current.contains(e.target) &&
        isBothModalsClosed
      ) {
        setDrawerOpen(false);
        setIsCollapsed(true);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [isConfirmModalOpen, isConfirmPreviewModal, previewUnCat, setDrawerOpen]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={drawerStyle}>
      <ReactDrawer
        className={`drawer-container ${isCollapsed ? 'collapsed' : ''}`}
        id={id}
        enableOverlay={!isCollapsed}
        open={isDrawerOpen}
        direction={direction}
        {...rest}
      >
        <div
          className={`drawer-refWrapper ${AiChatScroll ? '' : 'scrollable'}`}
          ref={menu}
        >
          <div className="drawer-title-wrap">
            <div
              style={{
                marginRight: '50px',
                // textAlign: 'justify',
              }}
            >
              <h4 className="drawer-title">{title}</h4>
              {subTitle ? (
                <h6 className="drawer-subtitle">{subTitle}</h6>
              ) : null}
            </div>
            {!isCollapsed && (
              <div className="drawer-close">
                <IoIosClose onClick={onClose} style={{ fontSize: '30px' }} />
              </div>
            )}
          </div>
          {isCollapse && (
            <div
              className={
                isCollapsed ? 'closemenu-collapse' : 'closemenu-drawer'
              }
              onClick={toggleCollapse}
            >
              {isCollapsed ? (
                <FiArrowLeftCircle className="fsdkm" />
              ) : (
                <FiArrowRightCircle
                  // onClick={handleSaveAIResponse}
                  className="fsdkm"
                />
              )}
            </div>
          )}
          {children}
        </div>
      </ReactDrawer>
    </div>
  );
};

Drawer.propTypes = {
  id: string,
  isDrawerOpen: bool.isRequired,
  direction: oneOf(['left', 'right', 'top', 'bottom']),
  children: node,
  title: string,
  subTitle: string,
  setDrawerOpen: func,
  onClose: func,
};

export default Drawer;
