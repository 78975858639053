import React, { useEffect, useState } from 'react';
import Table from 'components/Grid';
import { useSelector } from 'react-redux';
import {
  getFundCompareData,
  getFundCompareFields,
} from 'modules/home/slice/homeSlice';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { marked } from 'marked';

const FundCompareTable = ({ fundKey = '' }) => {
  const [columnList, setColumnList] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [fundField, setFundField] = useState([]);
  const { fundCompareTable } = useSelector(({ fundData }) => fundData);
  const [fundCompareData, setFundCompareData] = useState([]);
  const dispatch = useDispatch();
  const { search, state, pathname } = useLocation();

  useEffect(() => {
    const fundIds = fundCompareTable?.fund_compare_metadata_in_json?.fund_ids;
    const clonedFundIds = fundIds ? [...fundIds] : [];
    const sortedFundIdArray = clonedFundIds
      ?.sort((a, b) => a.displayOrder - b.displayOrder)
      ?.map((fund) => fund?.fund_id);
    let data = {
      fund_ids:
        sortedFundIdArray.length > 0
          ? sortedFundIdArray
          : fundKey
            ? [Number(fundKey)]
            : fundCompareTable?.fund_id,
    };

    dispatch(getFundCompareData(data))
      .then((res) => {
        setFundCompareData(res?.payload?.data?.data);
      })
      .catch((err) => {
        console.error('Error fetching fund compare data:', err);
      });
  }, [fundCompareTable, fundKey, dispatch]);

  useEffect(() => {
    if (!fundField.length) {
      dispatch(
        getFundCompareFields({
          fund_id: fundKey ? fundKey : fundCompareTable?.fund_id,
        })
      ).then((raw) => {
        if (raw?.payload?.status === 200) {
          setFundField(raw?.payload?.data?.data);
        }
      });
    }
  }, [dispatch, fundKey, fundCompareTable?.fund_id, fundField.length]);

  const MarkdownCellRenderer = (params) => {
    const { value } = params;
    const htmlContent = marked(value || '');
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  const getValueByKey = (sectionData, key) => {
    const [base_key, sub_key] = key?.split('.');

    let baseItems = [];

    for (const item of sectionData) {
      const itemsArray = item[base_key] || [];
      baseItems = baseItems.concat(itemsArray);
    }
    // Find the first item with the sub_key
    const itemWithSubKey = baseItems.find(
      (item) => item[sub_key] !== undefined
    );
    const data = itemWithSubKey ? itemWithSubKey[sub_key] : undefined;
    if (base_key === 'fund-fees') {
      const data = baseItems.filter((item) => item.fee_type === sub_key);
      const datas = data.map((res) => {
        return `${res?.fee_value || ''} ${res?.fee_description || ''}`.trim();
      });
      const joinedValues = datas.join('\n');
      return joinedValues;
    }

    if (data === null || data === 'null' || data === 'N/A') {
      return '';
    }
    return data;
  };

  useEffect(() => {
    const dynamicColumns =
      fundCompareTable?.fund_compare_metadata_in_json?.column_data || [];

    // Add the "Name" column by default
    const defaultColumns = [
      {
        field: 'Name',
        displayOrder: 1,
        accessor: 'Name',
        id: 'Name',
        Header: 'Name',
      },
      {
        field: 'Inception Year',
        displayOrder: 2,
        accessor: 'Inception Year',
        id: 'Inception Year',
        Header: 'Inception Year',
      },
      {
        field: 'Size',
        displayOrder: 3,
        accessor: 'Size',
        id: 'Size',
        Header: 'Size',
      },
      {
        field: 'Max Size',
        displayOrder: 4,
        accessor: 'Max Size',
        id: 'Max Size',
        Header: 'Max Size',
      },
      {
        field: 'Investment Period',
        displayOrder: 5,
        accessor: 'Investment Period',
        id: 'Investment Period',
        Header: 'Investment Period',
      },
      {
        field: 'Duration',
        displayOrder: 6,
        accessor: 'Duration',
        id: 'Duration',
        Header: 'Duration',
      },
      {
        field: 'Extension',
        displayOrder: 7,
        accessor: 'Extension',
        id: 'Extension',
        Header: 'Extension',
      },
      {
        field: 'Management Fee',
        displayOrder: 8,
        accessor: 'Management Fee',
        id: 'Management Fee',
        Header: 'Management Fee',
      },
      {
        field: 'Carried Interest',
        displayOrder: 9,
        accessor: 'Carried Interest',
        id: 'Carried Interest',
        Header: 'Carried Interest',
      },
      {
        field: 'Preferred Return',
        displayOrder: 10,
        accessor: 'Preferred Return',
        id: 'Preferred Return',
        Header: 'Preferred Return',
      },
      {
        field: 'GP Commitment',
        displayOrder: 11,
        accessor: 'GP Commitment',
        id: 'GP Commitment',
        Header: 'GP Commitment',
      },
    ];

    const filteredDynamicColumns = dynamicColumns?.filter(
      (column) => column.field !== 'Name'
    );

    // Merge default columns with dynamic ones
    const columnsWithAccessor =
      dynamicColumns.length > 0
        ? dynamicColumns.map((column) => ({
            ...column,
            accessor: column.field,
            id: column.field,
            Header: column.field,
            Cell: MarkdownCellRenderer, // Ensure Header is set for table display
          }))
        : defaultColumns;

    const sortedColumns = columnsWithAccessor?.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );

    setColumnList(sortedColumns);
  }, [fundCompareTable?.fund_compare_metadata_in_json?.column_data]);

  useEffect(() => {
    const data = fundCompareData?.map((raw) => {
      const dynamicFields =
        fundCompareTable?.fund_compare_metadata_in_json?.column_data
          ?.filter((colData) => colData?.field !== 'Name')
          ?.map((colData) => {
            const displayName = fundField?.find(
              (field) => field?.displayName === colData?.field
            )?.key;

            if (displayName) {
              return {
                [colData?.field]: getValueByKey(raw?.sections, displayName),
              };
            }
            return null;
          })
          ?.filter((item) => item !== null);

      return {
        fundId: raw?.fund_id,
        Name: getValueByKey(raw?.sections, 'fund-details.name'),
        'Inception Year': getValueByKey(
          raw?.sections,
          'fund-details.inception_year'
        ),
        Size: getValueByKey(raw?.sections, 'fund-details.size'),
        'Max Size': getValueByKey(raw?.sections, 'fund-details.max_fund_size'),
        'Investment Period': getValueByKey(
          raw?.sections,
          'fund-terms.investment_period'
        ),
        Duration: getValueByKey(raw?.sections, 'fund-terms.fund_duration'),
        Extension: getValueByKey(raw?.sections, 'fund-terms.extension'),
        'Management Fee': getValueByKey(
          raw?.sections,
          'fund-fees.Management Fee'
        ),
        'Carried Interest': getValueByKey(
          raw?.sections,
          'fund-terms.carried_interest'
        ),
        'Preferred Return': getValueByKey(
          raw?.sections,
          'fund-terms.preferred_return'
        ),
        'GP Commitment': getValueByKey(
          raw?.sections,
          'fund-terms.gp_commitment'
        ),
        ...dynamicFields?.reduce((acc, item) => ({ ...acc, ...item }), {}),
      };
    });

    setVisibleData(data);
  }, [
    fundCompareData,
    fundCompareTable?.fund_compare_metadata_in_json?.column_data,
    fundField,
  ]);

  return (
    <Table
      allowAdd
      allowExport
      onAdd={alert}
      data={visibleData}
      isLoading={false}
      columns={columnList}
      customSorting={false}
      customPagination={false}
      addButtonName="Add User"
      sectionName="BlackBookFundCompare"
      // scrollStyle="scrollable-fund scroll fix-height"
      ContainerClass={
        pathname == '/black-book'
          ? 'table-container-fund'
          : 'table-container-fund blackbook-new'
      }
    />
  );
};

export default FundCompareTable;
