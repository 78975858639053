import fetchFromApiServer from 'services/api';

export function fetchFundDetails(fund_ID) {
  const url = `funds/fund-details/${fund_ID}`;
  return fetchFromApiServer('GET', url, fund_ID);
}

export function fetchPdfFund(data) {
  const url = `funds/pdf`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchCompareFundData(data) {
  const url = `funds/compare`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchUpdateCompareFundData(data) {
  const url = `funds/fund-compare-update`;
  return fetchFromApiServer('PUT', url, data);
}

export function fetchCompareFundField(data) {
  const url = `funds/compare-fields`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchUsersList() {
  const url = `funds/getListUsers`;
  return fetchFromApiServer('GET', url, {});
}

export function fetchCioUsersList() {
  const url = `funds/cio-users-list`;
  return fetchFromApiServer('GET', url, {});
}

export function fetchSendEmail(data) {
  const url = `funds/send-mail`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchSendEmailTemplate(data) {
  const url = `funds/send-mail-template`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchDDDetails(fund_ID) {
  const url = `funds/get-ddChecklist`;
  return fetchFromApiServer('GET', url, {}, fund_ID);
}

export function fetchFundBenchmark(fund_ID) {
  const url = `funds/fund-quartiles`;
  return fetchFromApiServer('GET', url, {}, fund_ID);
}

export function updateFundBenchmark(data) {
  const url = `funds/set-benchmarks`;
  return fetchFromApiServer('PUT', url, data);
}

export function deleteFundBenchmark(data) {
  const url = `funds/delete-benchmarks`;
  return fetchFromApiServer('DELETE', url, {}, data);
}

export function addFundBenchmark(data) {
  const url = `funds/add-benchmarks`;
  return fetchFromApiServer('POST', url, {}, data);
}

export function fetchScoringMatrix(data) {
  const url = `funds/get-scoringMatrix`;
  return fetchFromApiServer('GET', url, {}, data);
}

export function updateDDList(data) {
  const url = `funds/update-ddChecklist`;
  return fetchFromApiServer('PUT', url, data);
}

export function updateScoringMatrix(data) {
  const url = `funds/update-scoringMatrix`;
  return fetchFromApiServer('PUT', url, data);
}

export function fetchSectionList(data) {
  const url = `funds/fund-sections-review`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchFundSectionReview(fund_ID) {
  const url = `funds/fund-sections-review/${fund_ID}`;
  return fetchFromApiServer('GET', url, fund_ID);
}

export function fetchSourceDocList(doc_path) {
  const url = `funds/fund-document-name`;
  return fetchFromApiServer('POST', url, doc_path);
}

export function fetchFundDocuments(fund_ID) {
  const url = `funds/fund-documents/${fund_ID}`;
  return fetchFromApiServer('GET', url, fund_ID);
}

export function fetchFundDocumentsType(data) {
  const url = `funds/fund-document-type`;
  return fetchFromApiServer('PUT', url, data);
}

export function updateFundStatus(requestObject) {
  const url = `funds/update-fund-status`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function fetchFundPdfUrl(data) {
  const url = `funds/source-docs/signurl`;
  return fetchFromApiServer('POST', url, data);
}
